import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'
import "firebase/performance";
import { API_URL } from './params'
// import * as firebase from 'firebase';
const producofig = {
  apiKey: 'AIzaSyAP4U2zaW_4G6yBsBioRntTOXHgiq4Sqkc',
  authDomain: 'chatbot.appypie.com',
  databaseURL: 'https://chatbot-production-d6ea3.firebaseio.com',
  projectId: 'chatbot-production-d6ea3',
  storageBucket: "gs://chatimages.appypie.com",
  messagingSenderId: '386870191393',
  appId: '1:386870191393:web:420cb09b0a90bbd83f4a60',
  measurementId: 'G-QBQQN39BRF'
}
const devCofig = {
  apiKey: 'AIzaSyAMt4qRjSSCXj-XkHiAm2vSp75Wcq14sx0',
  authDomain: 'appycx-beta.firebaseapp.com',
  databaseURL: 'https://appycx-beta.firebaseio.com',
  projectId: 'appycx-beta',
  storageBucket: 'appycx-beta.appspot.com',
  messagingSenderId: '491738005705',
  appId: '1:491738005705:web:0dde81c054aaf314da0d18',
  measurementId: 'G-KZ9D7MLQCK'
}

const config = process.env.REACT_APP_STAGE=="prod"? producofig:devCofig
var TOKEN_URL = API_URL + '/getAuthToken/?client_token=';
// if(process.env.REACT_APP_STAGE=="prod"){
// TOKEN_URL ='https://us-central1-chatbot-production-d6ea3.cloudfunctions.net/getAuthToken?client_token='
// }else{
//   TOKEN_URL = 'https://us-central1-appycx-beta.cloudfunctions.net/getAuthToken?client_token=';
// }
firebase.initializeApp(config);
firebase.analytics();
firebase.firestore().settings({ experimentalForceLongPolling: true });

firebase.performance();

// const db = firebase.firestore()
const fnAuth = async () => {
  //alert("calling auth for already loggedin user")
  if(!localStorage.getItem("fbAuthToken")){
  let res = await fetch(
    TOKEN_URL+
      localStorage.getItem('accessToken') +
      '&_id=' +
      localStorage.getItem('cognito_id') +
      '&alias=' +
      localStorage.getItem('tablealias')
  )
  const token = await res.text()
  // console.log(token,"token_fb_auth")
  localStorage.setItem("fbAuthToken",token);
  await firebase
    .auth()
    .signInWithCustomToken(localStorage.getItem("fbAuthToken"))
    .then(userCredential => {
   //   alert("auth success for loggedin")
   
      console.log(userCredential, 'credentials')
    })
    .catch(error => {
      console.log(error, 'error_infirebaseAuth')
      localStorage.clear()
    })
  }else{
    await firebase
    .auth()
    .signInWithCustomToken(localStorage.getItem("fbAuthToken"))
    .then(async userCredential => {
   //   alert("auth success for loggedin")
   
      console.log(userCredential, 'credentials')
    })
    .catch(async error => {
      console.log(error, 'error_infirebaseAuth')

      let res = await fetch(
        TOKEN_URL+
          localStorage.getItem('accessToken') +
          '&_id=' +
          localStorage.getItem('cognito_id') +
          '&alias=' +
          localStorage.getItem('tablealias')
      )
      const token = await res.text()
      // console.log(token,"token_fb_auth")
      localStorage.setItem("fbAuthToken",token);
      await firebase
        .auth()
        .signInWithCustomToken(localStorage.getItem("fbAuthToken"))
        .then(userCredential => {
       //   alert("auth success for loggedin")
       
          console.log(userCredential, 'credentials')
        })
        .catch(error => {
          console.log(error, 'error_infirebaseAuth')
          localStorage.clear()

        })


    })
  }
  // firebaseAuth=fbAuthToken
  
}
if(localStorage.getItem('tablealias')){ 
  fnAuth()
}



export const fb = firebase
export const googleProvider = new firebase.auth.GoogleAuthProvider()
export const ref = firebase.database().ref()
export const storageConnection = firebase.storage()
export const firebaseAuth = firebase.auth
export const db =firebase.firestore()
